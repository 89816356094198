// Import is needed for OvenPlayer to find dash.js
import * as Dash from 'dashjs';

import * as OvenPlayer from 'ovenplayer';

let player;

document.addEventListener('DOMContentLoaded', init);

function init() {
  const appHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
  window.addEventListener('resize', () => {
    appHeight();
    updatePlayerSize();
  });

  appHeight();

  window.addEventListener('load', () => {
    updatePlayerSize();
  });

  const enableLowLatency = document.querySelector('#low-latency').checked;
  initPlayer(enableLowLatency);

  document.querySelector('#low-latency').addEventListener('change', () => {
    const enableLowLatency = document.querySelector('#low-latency').checked;
    initPlayer(enableLowLatency);
  });
}

/**
 * Returns how much space in pixels on the user's screen is available for actual page content (excluding the header etc.)
 * 
 * @returns {number}
 */
function getContentHeight() {
  const contentHeightStyle = document.documentElement.style.getPropertyValue('--content-height')?.split('px')?.[0];

  if (contentHeightStyle === '') {
    // CSS variable not available, calculate by hand...

    const navHeight = document.querySelector('#topmenu').clientHeight;
    const appHeight = document.documentElement.style.getPropertyValue('--app-height')?.split('px')?.[0];

    const contentHeight = appHeight - navHeight;

    return contentHeight;
  }

  return contentHeightStyle;
}

/**
 * Make sure, the player adapts its width to the current height of the player box.
 * The height of the player box is usually the maximum possible height so that the maximum area of the screen is filled by the video.
 */
function updatePlayerSize() {
  // Leave some space for scrollbar that may pop up after resizing
  const width = document.documentElement.clientWidth - 30;
  const height = getContentHeight();

  const heightByWidth = Math.floor(width * (9 / 16));
  const widthByHeight = Math.floor(height * (16 / 9));

  if (heightByWidth <= height) {
    setPlayerSize(width, heightByWidth)
  } else {
    setPlayerSize(widthByHeight, height);
  }
}

/**
 * 
 * @param {number} width 
 * @param {number} height 
 */
function setPlayerSize(width, height) {
  const playerBox = document.querySelector('#player-box');
  playerBox.style.width = width.toString() + 'px';
  playerBox.style.height = height.toString() + 'px';
}

function initPlayer(lowLatencyEnabled = false) {
  player?.remove();

  const lowLatencySettings = {};

  if (lowLatencyEnabled === true) {
    lowLatencySettings.maxLiveSyncPlaybackRate = 2;
    lowLatencySettings.liveSyncDuration = 3;
  }

  player = OvenPlayer.create("bs-twitch-player", {
    sources: [
      {
        type: "ll-hls",
        framerate: 30,
        file: process.env.STREAM_URL + "/live/test/abr.m3u8",
        label: "HLS Low Latency"
      },
      {
        type: "hls",
        framerate: 30,
        file: process.env.STREAM_URL + "/live/test_fhdo/playlist.m3u8",
        label: "FHD HLS"
      },
      {
        type: "hls",
        framerate: 30,
        file: process.env.STREAM_URL + "/live/test_hdo/playlist.m3u8",
        label: "HD HLS"
      },
    ],
    hlsConfig: {
      ...lowLatencySettings,
    }
  });

  player.on("error", function (error) {
    console.log(error);
  });
}
